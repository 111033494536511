import { gql } from 'graphql-request'

export const cardTemplateBriefFragment = gql`
  fragment cardTemplateBriefType on cardTemplate {
    id
    name
    price
    type
  }
`

export const cardBriefFragment = gql`
  fragment cardBriefType on card {
    id
    activatedAt
    canceledDate
    createdAt
    subscriptionInfo
    cardPeriod
  }
`

export const cardTemplateFragment = gql`
  ${cardTemplateBriefFragment}
  ${cardBriefFragment}
  fragment cardTemplateType on cardTemplate {
    ...cardTemplateBriefType
    availableToAllBranches
    cardPeriod
    archived
    cardTemplateServices {
      servicePrice
      serviceQuantity
      serviceId
    }
    cards {
      ...cardBriefType
    }
  }
`

export const cardTemplateAsClientFragment = gql`
  fragment cardTemplateAsClientType on cardTemplate {
    id
    name
    price
    type
    availableToAllBranches
    cardPeriod
    archived
    cardTemplateServices {
      servicePrice
      serviceQuantity
      serviceId
    }
  }
`
