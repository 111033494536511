import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { FC } from 'react'
import { SaveButton } from 'widgets/Buttons'
import { PaymentProviderList } from './List'
import { useOpenPaymentProviderDialog } from 'widgets/PaymentProviderDialog'

export const PaymentProvidersPage: FC = () => {
  const { data: myPermission } = useFetchMyPermissions()

  const { openEditPaymentProviderDialog, openCreatePaymentProviderDialog, paymentProviderDialog } =
    useOpenPaymentProviderDialog()

  const isEditingAllowed = myPermission?.includes(permissions.business.set)

  return (
    <Page>
      {isEditingAllowed && (
        <div className="flex mb-3">
          <SaveButton
            onClick={() => {
              openCreatePaymentProviderDialog()
            }}
            className="self-start"
            isCreate
          />
        </div>
      )}

      <PaymentProviderList onRowClick={account => openEditPaymentProviderDialog(account.id)} />
      {paymentProviderDialog}
    </Page>
  )
}
