import { permissions } from '@expane/logic/permission'
import { SoftwarePOSsPage } from 'pages/SoftwarePOSsPage'
import {
  IoBagHandleOutline,
  IoBagOutline,
  IoBusinessOutline,
  IoCalendarOutline,
  IoCardOutline,
  IoCubeOutline,
  IoDocumentsOutline,
  IoFileTrayFullOutline,
  IoJournalOutline,
  IoKeyOutline,
  IoLayersOutline,
  IoListOutline,
  IoPeopleCircleOutline,
  IoPeopleOutline,
  IoPodiumOutline,
  IoPricetagsOutline,
  IoReaderOutline,
  IoReceiptOutline,
  IoSparklesOutline,
  IoTodayOutline,
  IoWalletOutline,
} from 'react-icons/io5'
import { BookingsPage } from 'pages/BookingsPage'
import { ArchiveLocationsPage, LocationsPage } from 'pages/LocationsPage'
import { WaitingListPage } from 'pages/WaitingListPage'
import { ClientsPage } from 'pages/ClientsPage'
import { GiftCardsPage } from 'pages/CardsPage'
import { TagsPage } from 'pages/TagsPage'
import { ArchiveClientsPage } from 'pages/ArchiveClientsPage'
import { ArchiveServicesPage, ServicesPage } from 'pages/ServicesPage'
import { SubscriptionsPage } from 'pages/SubscriptionsPage'
import { ArchiveEmployeesPage, EmployeesPage } from 'pages/EmployeesPage'
import { SchedulePage } from 'pages/SchedulesPage'
import { AccountsPage } from 'pages/AccountsPage'
import { CashRegisterIcon, StorageIcon, TruckIcon } from 'ui/Icons'
import { StoragePage } from 'pages/StoragePage'
import { ArchiveProductsPage, ProductsPage } from 'pages/ProductsPage'
import { InventoryPage } from 'pages/InventoryPage'
import { SuppliersPage } from 'pages/SuppliersPage'
import { AnalyticsPage } from 'pages/AnalyticsPage'
import { ReportsPage } from 'pages/ReportsPage'
import { NavigationRouteType, RouteType } from 'routes/logic'
import { MovementsPage } from 'pages/MovementsPage'
import { FinancesPage } from 'pages/FinancesPage'
import { BookingsListPage } from 'pages/BookingsListPage'
import { ArchiveSchedulesPage } from 'pages/ArchiveSchedulesPage'
import { ArchiveGiftCardsPage } from 'pages/ArchiveCardsPage'
import { ArchiveSubscriptionsPage } from 'pages/ArchiveSubscriptionsPage'
import { ArchiveTagsPage } from 'pages/ArchiveTagsPage'
import { SalaryPage } from 'pages/SalaryPage'
import { PermissionsPage } from 'pages/PermissionsPage'
import { ScheduleTemplates } from 'pages/SchedulesPage/ScheduleTemplateSettings'
import { ReviewsPage } from 'pages/ReviewsPage'
import { ArchiveReviewsPage } from 'pages/ReviewsPage/ArchiveReviewsPage'
import { PaymentProvidersPage } from 'pages/PaymentProvidersPage'

export const EMPLOYEE_PERMISSIONS_PATH = '/employees/permissions'

export const navigationRoutes: Array<NavigationRouteType> = [
  // Bookings
  {
    name: 'bookingsTitle',
    path: '/bookings',
    isPrivate: true,
    Icon: IoCalendarOutline,
    element: BookingsPage,
    children: [
      {
        name: 'locations.name',
        path: '/bookings/locations',
        isPrivate: true,
        permissions: [permissions.location.get],
        Icon: IoBusinessOutline,
        element: LocationsPage,
        parentRoute: { path: '/bookings', name: 'bookingsTitle' },
      },
      {
        name: 'waitingList.name',
        path: '/bookings/waitingList',
        isPrivate: true,
        permissions: [permissions.waitingList.get],
        Icon: IoReaderOutline,
        element: WaitingListPage,
        parentRoute: { path: '/bookings', name: 'bookingsTitle' },
      },
      {
        name: 'list',
        path: '/bookings/list',
        isPrivate: true,
        Icon: IoListOutline,
        element: BookingsListPage,
        parentRoute: { path: '/bookings', name: 'bookingsTitle' },
      },
    ],
  },

  // Clients
  {
    name: 'clients',
    path: '/clients',
    isPrivate: true,
    permissions: [permissions.client.get],
    Icon: IoPeopleOutline,
    element: ClientsPage,
    children: [
      {
        name: 'cards',
        path: '/clients/cards',
        isPrivate: true,
        permissions: [permissions.card.get],
        Icon: IoCardOutline,
        element: GiftCardsPage,
        parentRoute: { path: '/clients', name: 'clients' },
        modules: ['giftCards'],
      },
      {
        Icon: IoPricetagsOutline,
        name: 'tags',
        path: '/clients/tags',
        isPrivate: true,
        permissions: [permissions.client.get],
        element: TagsPage,
        parentRoute: { path: '/clients', name: 'clients' },
      },
      {
        Icon: IoSparklesOutline,
        name: 'reviews.name',
        path: '/clients/reviews',
        isPrivate: true,
        permissions: [permissions.review.get],
        element: ReviewsPage,
        parentRoute: { path: '/clients', name: 'clients' },
      },
    ],
  },

  // Services
  {
    name: 'services',
    path: '/services',
    isPrivate: true,
    permissions: [permissions.service.get],
    Icon: IoReaderOutline,
    element: ServicesPage,
    children: [
      {
        name: 'subscriptions',
        path: '/services/subscriptions',
        isPrivate: true,
        permissions: [permissions.card.get],
        Icon: IoJournalOutline,
        element: SubscriptionsPage,
        parentRoute: { path: '/services', name: 'services' },
        modules: ['subscriptions'],
      },
    ],
  },

  // Employees
  {
    name: 'employees.name',
    path: '/employees',
    isPrivate: true,
    permissions: [permissions.employee.get],
    Icon: IoPeopleCircleOutline,
    element: EmployeesPage,
    children: [
      {
        name: 'schedules',
        path: '/employees/schedules',
        isPrivate: true,
        permissions: [permissions.schedule.get],
        Icon: IoTodayOutline,
        element: SchedulePage,
        parentRoute: { path: '/employees', name: 'employees.name' },
      },
      {
        name: 'permissions.name',
        path: EMPLOYEE_PERMISSIONS_PATH,
        isPrivate: true,
        permissions: [permissions.rolePermission.set],
        Icon: IoKeyOutline,
        element: PermissionsPage,
        parentRoute: { path: '/employees', name: 'employees.name' },
      },
    ],
  },
  // Finances
  {
    name: 'finances',
    path: '/finances',
    isPrivate: true,
    permissions: [permissions.transaction.get, permissions.account.get],
    filterByEveryPermission: true,
    Icon: IoWalletOutline,
    element: FinancesPage,
    children: [
      {
        name: 'accounts',
        path: '/finances/accounts',
        isPrivate: true,
        permissions: [permissions.account.get],
        Icon: IoLayersOutline,
        element: AccountsPage,
        parentRoute: { path: '/finances', name: 'finances' },
      },
      {
        name: 'paymentProviders',
        path: '/finances/payment-providers',
        isPrivate: true,
        Icon: IoBagOutline,
        element: PaymentProvidersPage,
        parentRoute: { path: '/finances', name: 'finances' },
      },
      {
        name: 'softwarePOSs',
        path: '/finances/software-poss',
        isPrivate: true,
        permissions: [permissions.account.get],
        Icon: CashRegisterIcon,
        element: SoftwarePOSsPage,
        parentRoute: { path: '/finances', name: 'finances' },
        modules: ['checkbox'],
      },
      {
        name: 'salaries',
        path: '/finances/salaries',
        isPrivate: true,
        Icon: IoWalletOutline,
        element: SalaryPage,
        parentRoute: { path: '/finances', name: 'finances' },
      },
    ],
  },
  // Store
  {
    name: 'storage.name',
    path: '/storage',
    isPrivate: true,
    permissions: [permissions.movement.get, permissions.product.get],
    filterByEveryPermission: true,
    Icon: IoBagHandleOutline,
    element: MovementsPage,
    modules: ['productsForSale', 'consumables'],
    children: [
      {
        name: 'products',
        path: '/storage/products',
        isPrivate: true,
        permissions: [permissions.product.get],
        filterByEveryPermission: true,
        Icon: IoCubeOutline,
        element: ProductsPage,
        parentRoute: { path: '/storage', name: 'storage.name' },
        modules: ['productsForSale', 'consumables'],
      },
      {
        name: 'storages',
        path: '/storage/storages',
        isPrivate: true,
        permissions: [permissions.storage.get],
        Icon: StorageIcon,
        element: StoragePage,
        parentRoute: { path: '/storage', name: 'storage.name' },
        modules: ['productsForSale', 'consumables'],
      },
      {
        name: 'inventory.name',
        path: '/storage/inventory',
        isPrivate: true,
        permissions: [permissions.inventory.get],
        Icon: IoReceiptOutline,
        element: InventoryPage,
        parentRoute: { path: '/storage', name: 'storage.name' },
        modules: ['productsForSale', 'consumables'],
      },
      {
        name: 'suppliers',
        path: '/storage/suppliers',
        isPrivate: true,
        permissions: [permissions.supplier.get],
        Icon: TruckIcon,
        element: SuppliersPage,
        parentRoute: { path: '/storage', name: 'storage.name' },
        modules: ['productsForSale', 'consumables'],
      },
    ],
  },
  // Analytics
  {
    name: 'analytics',
    path: '/analytics',
    isPrivate: true,
    permissions: [permissions.analytics.get],
    Icon: IoPodiumOutline,
    element: AnalyticsPage,
    children: [
      {
        name: 'reports.name',
        path: '/analytics/reports',
        isPrivate: true,
        permissions: [permissions.analytics.get],
        Icon: IoDocumentsOutline,
        element: ReportsPage,
        parentRoute: { path: '/analytics', name: 'analytics' },
      },
    ],
  },
]

// TODO: убрать ненужные поля с обьекта и поправить типы
export const archiveRoutes: Array<RouteType> = [
  {
    name: 'archive.name',
    path: '/bookings/locations/archive',
    isPrivate: true,
    permissions: [permissions.location.archive],
    Icon: IoFileTrayFullOutline,
    element: ArchiveLocationsPage,
    parentRoute: { path: '/bookings/locations', name: 'locations.name' },
  },
  {
    name: 'archive.name',
    path: '/clients/archive',
    isPrivate: true,
    permissions: [permissions.client.set],
    Icon: IoFileTrayFullOutline,
    element: ArchiveClientsPage,
    parentRoute: { path: '/clients', name: 'clients' },
  },
  {
    name: 'archive.name',
    path: '/clients/tags/archive',
    isPrivate: true,
    permissions: [permissions.client.set],
    Icon: IoFileTrayFullOutline,
    element: ArchiveTagsPage,
    parentRoute: { path: '/clients/tags', name: 'tags' },
  },
  {
    name: 'archive.name',
    path: '/services/archive',
    isPrivate: true,
    permissions: [permissions.service.archive],
    Icon: IoFileTrayFullOutline,
    element: ArchiveServicesPage,
    parentRoute: { path: '/services', name: 'services' },
  },
  {
    name: 'archive.name',
    path: '/employees/archive',
    isPrivate: true,
    permissions: [permissions.employee.archive],
    Icon: IoFileTrayFullOutline,
    element: ArchiveEmployeesPage,
    parentRoute: { path: '/employees', name: 'employees.name' },
  },
  {
    name: 'archive.name',
    path: '/storage/products/archive',
    isPrivate: true,
    permissions: [permissions.product.archive],
    Icon: IoFileTrayFullOutline,
    element: ArchiveProductsPage,
    parentRoute: { path: '/storage/products', name: 'products' },
  },
  {
    name: 'archive.name',
    path: '/clients/cards/archive',
    isPrivate: true,
    permissions: [permissions.card.get],
    Icon: IoFileTrayFullOutline,
    element: ArchiveGiftCardsPage,
    parentRoute: { path: '/clients/cards', name: 'cards' },
  },
  {
    name: 'reviews.name',
    path: '/clients/reviews/archive',
    isPrivate: true,
    permissions: [permissions.review.set],
    Icon: IoFileTrayFullOutline,
    element: ArchiveReviewsPage,
    parentRoute: { path: '/clients/reviews', name: 'reviews.name' },
  },
  {
    name: 'archive.name',
    path: '/services/subscriptions/archive',
    isPrivate: true,
    permissions: [permissions.card.get],
    Icon: IoJournalOutline,
    element: ArchiveSubscriptionsPage,
    parentRoute: { path: '/services/subscriptions', name: 'subscriptions' },
  },
  {
    name: 'archive.name',
    path: '/employees/schedules/templates/archive',
    isPrivate: true,
    permissions: [permissions.schedule.get],
    Icon: IoTodayOutline,
    element: ArchiveSchedulesPage,
    parentRoute: { path: '/employees/schedules', name: 'schedules' },
  },
  // TODO: routes которые не отображаются в основном меню, для них нужно создать отдельный массив - ?
  // или переименовать текущий, чтоб было понятно что здесь не только архивные
  {
    name: 'schedule.scheduleTemplates',
    path: '/employees/schedules/templates',
    isPrivate: true,
    permissions: [permissions.schedule.get],
    Icon: IoTodayOutline,
    element: ScheduleTemplates,
    parentRoute: { path: '/employees', name: 'employees.name' },
  },
]
