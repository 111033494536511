import { gql } from 'graphql-request'

export const orderAsClientFragment = gql`
  fragment orderAsClientType on order {
    id
    amount
    currency
    paymentProviderId
    status
    createdAt
    orderCardTemplates {
      price
      cardTemplateId
    }
    orderServices {
      price
      serviceId
    }
  }
`
