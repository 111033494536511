import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import {
  ServerCardTemplateAsClientType,
  ServerCardTemplateBriefType,
  ServerCardTemplateType,
} from '../../generated/graphql-types'
import { CARD_TEMPLATES_KEY } from './queryKeys'
import { BRANCHES_QUERY_KEY } from '../branch/queryKeys'
import { gql } from 'graphql-request'
import {
  cardTemplateAsClientFragment,
  cardTemplateBriefFragment,
  cardTemplateFragment,
} from '../cardTemplate/cardTemplate.fragments'
import { parseDatesInCardGqlResponse } from '../card/logic'
import { DEFAULT_TIMEZONE } from '@expane/date'
import {
  ARCHIVED_CARD_TEMPLATES_QUERY_KEY,
  CARDS_BRIEFS_QUERY_KEY,
  GIFT_CARDS_QUERY_KEY,
  SUBSCRIPTIONS_QUERY_KEY,
} from '../card/queryKeys'
import { CARD_TYPES, CardTemplateWithSubscriptionInfoType } from './logic'
import { AS_CLIENT_QUERY_KEY } from '../client'

function useFetchCardTemplates(timezone: string | undefined, branchId: number | undefined) {
  return useQuery<ServerCardTemplateType[]>(
    [CARD_TEMPLATES_KEY, BRANCHES_QUERY_KEY, { branchId }],

    async () => {
      const result = await request(
        gql`
          ${cardTemplateFragment}
          query ($branchId: Int!) {
            cardTemplates(
              order_by: { createdAt: desc }
              where: {
                archived: { _is_null: true }
                _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
              }
            ) {
              ...cardTemplateType
            }
          }
        `,
        { branchId },
      )
      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates.map(cardTemplate => ({
          ...cardTemplate,
          cards: cardTemplate.cards.map(card =>
            parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE),
          ),
        }))
      }

      reportError(new Error('cards is not an array'), 'error')
      return []
    },
    {
      queryName: 'useFetchCardTemplates',
      onError: reportGqlError,
      onSuccess: cards =>
        cards.forEach(card => queryClient.setQueryData([CARD_TEMPLATES_KEY, card.id], card)),
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

function useFetchCardTemplatesBriefs(branchId: number | undefined) {
  return useQuery<ServerCardTemplateBriefType[]>(
    [CARDS_BRIEFS_QUERY_KEY, CARD_TEMPLATES_KEY, BRANCHES_QUERY_KEY, { branchId }],

    async () => {
      const result = await request(
        gql`
          ${cardTemplateBriefFragment}
          query ($branchId: Int!) {
            cardTemplates(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }
            ) {
              ...cardTemplateBriefType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates
      } else {
        reportError(new Error('cards brief is not an array'), 'error')
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchCardTemplatesBriefs',
      onError: reportGqlError,
    },
  )
}

function useFetchArchivedCardTemplates(branchId: number | undefined) {
  return useQuery<ServerCardTemplateType[]>(
    [ARCHIVED_CARD_TEMPLATES_QUERY_KEY, BRANCHES_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${cardTemplateFragment}
          query ($branchId: Int!) {
            cardTemplates(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: false } }
            ) {
              ...cardTemplateType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates
      } else {
        reportError(new Error('archived cards is not an array'), 'error')
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchArchivedCardTemplates',
      onError: reportGqlError,
      onSuccess: cards =>
        cards.forEach(card =>
          queryClient.setQueryData([ARCHIVED_CARD_TEMPLATES_QUERY_KEY, card.id], card),
        ),
    },
  )
}

export function useFetchSubscriptions(timezone: string | undefined, branchId: number | undefined) {
  const cards = useFetchCardTemplates(timezone, branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.subscription) }
}

export function useFetchSubscriptionsBriefs(branchId: number | undefined) {
  const cards = useFetchCardTemplatesBriefs(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.subscription) }
}

export function useFetchArchivedSubscriptions(branchId: number | undefined) {
  const cards = useFetchArchivedCardTemplates(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.subscription) }
}

export function useFetchGiftCards(timezone: string | undefined, branchId: number | undefined) {
  const cards = useFetchCardTemplates(timezone, branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.giftCard) }
}

export function useFetchGiftCardsBriefs(branchId: number | undefined) {
  const cards = useFetchCardTemplatesBriefs(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.giftCard) }
}

export function useFetchArchivedGiftCards(branchId: number | undefined) {
  const cards = useFetchArchivedCardTemplates(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.giftCard) }
}

export function useFetchCardTemplateById(id: number | undefined, timezone: string | undefined) {
  return useQuery<CardTemplateWithSubscriptionInfoType | undefined>(
    [CARD_TEMPLATES_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${cardTemplateFragment}
          query ($id: Int!) {
            cardTemplateById(id: $id) {
              ...cardTemplateType
            }
          }
        `,
        { id },
      )

      if (result?.cardTemplateById) {
        return {
          ...result.cardTemplateById,
          cards: result.cardTemplateById.cards.map(card =>
            parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE),
          ),
        }
      } else {
        reportError(new Error('cardById does not exist'), 'warning', {
          id,
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchCardById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )
}

export function useFetchTemplateGiftCardsAsClient(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerCardTemplateAsClientType[]>(
    [CARD_TEMPLATES_KEY, GIFT_CARDS_QUERY_KEY, AS_CLIENT_QUERY_KEY, { branchId }],

    async () => {
      const result = await request(
        gql`
          ${cardTemplateAsClientFragment}
          query ($branchId: Int!, $type: Int!) {
            cardTemplates(
              where: {
                type: { _eq: $type }
                archived: { _is_null: true }
                _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
              }
            ) {
              ...cardTemplateAsClientType
            }
          }
        `,
        { branchId, type: CARD_TYPES.giftCard },
      )
      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates
      }

      reportError(new Error('cardTemplates is not an array'), 'error')
      return []
    },
    {
      queryName: 'useFetchTemplateGiftCardsAsClient',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTemplateSubscriptionsAsClient(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerCardTemplateAsClientType[]>(
    [CARD_TEMPLATES_KEY, SUBSCRIPTIONS_QUERY_KEY, AS_CLIENT_QUERY_KEY, { branchId }],

    async () => {
      const result = await request(
        gql`
          ${cardTemplateAsClientFragment}
          query ($branchId: Int!, $type: Int!) {
            cardTemplates(
              where: {
                type: { _eq: $type }
                archived: { _is_null: true }
                _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
              }
            ) {
              ...cardTemplateAsClientType
            }
          }
        `,
        { branchId, type: CARD_TYPES.subscription },
      )
      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates
      }

      reportError(new Error('cardTemplates is not an array'), 'error')
      return []
    },
    {
      queryName: 'useFetchTemplateGiftCardsAsClient',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}
