import { Checkbox, ErrorMessage, InputLabel, Paper } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { PaymentProviderFormValues } from '@expane/logic/payment/providers'

interface PaymentProviderItemsConfigProps {
  control: Control<PaymentProviderFormValues>
  isEditingAllowed: boolean
}

export const PaymentProviderItemsConfig: FC<PaymentProviderItemsConfigProps> = ({
  control,
  isEditingAllowed,
}) => {
  const { t } = useTranslation()

  const { getModuleSetting } = useBusinessModulesSettings()

  const areGiftCardsEnabled = getModuleSetting('giftCards')
  const areSubscriptionsEnabled = getModuleSetting('subscriptions')
  const areProductsForSaleEnabled = getModuleSetting('productsForSale')
  const areServicesForSaleEnabled = getModuleSetting('servicesForSale')

  return (
    <Paper className="px-2 pt-2">
      <InputLabel
        className="mb-2"
        label={t('paymentProvider.paymentItemsConfig')}
        hint={t('paymentProvider.paymentItemsConfigHint')}
      />

      <div className="flex justify-between gap-2">
        <Controller
          control={control}
          name="paymentItemsConfig.booking"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              label={t('booking.name')}
              checked={value}
              onChange={onChange}
              disabled={!isEditingAllowed}
            />
          )}
        />

        {areSubscriptionsEnabled && (
          <Controller
            control={control}
            name="paymentItemsConfig.subscription"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label={t('subscription.name')}
                checked={value}
                onChange={onChange}
                disabled={!isEditingAllowed}
              />
            )}
          />
        )}

        {areGiftCardsEnabled && (
          <Controller
            control={control}
            name="paymentItemsConfig.giftCard"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label={t('giftCard.name')}
                checked={value}
                onChange={onChange}
                disabled={!isEditingAllowed}
              />
            )}
          />
        )}

        {areServicesForSaleEnabled && (
          <Controller
            control={control}
            name="paymentItemsConfig.additionalService"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label={t('serviceType.forSale')}
                checked={value}
                onChange={onChange}
                disabled={!isEditingAllowed}
              />
            )}
          />
        )}

        {areProductsForSaleEnabled && (
          <Controller
            control={control}
            name="paymentItemsConfig.product"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label={t('product.name')}
                checked={value}
                onChange={onChange}
                disabled={!isEditingAllowed}
              />
            )}
          />
        )}
      </div>

      <Controller
        control={control}
        name="paymentItemsConfig"
        rules={{
          validate: items => {
            if (!items) return false

            const values = Object.values(items)

            return !values.every(item => !item)
          },
        }}
        render={({ fieldState: { error } }) => (
          <ErrorMessage
            errorMessage={{
              isShown: Boolean(error),
              text: t('paymentProvider.paymentItemsConfigError'),
            }}
          />
        )}
      />
    </Paper>
  )
}
