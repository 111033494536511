import { useFetchAccounts, useFetchPaymentProviderById } from '@expane/data'
import { useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { FC, useRef } from 'react'
import { PaymentProviderDialogPlaceholder } from 'widgets/PaymentProviderDialog/PaymentProviderDialogPlaceholder'
import { PaymentProviderDialogLogic } from 'widgets/PaymentProviderDialog/PaymentProviderDialogLogic'
import { usePopupOpenState } from '@expane/ui'
import { store } from 'store'
import { observer } from 'mobx-react-lite'

interface PaymentProviderDialogProps {
  closeDialog: () => void
  isCreate: boolean
  id?: string
}

const PaymentProviderDialog: FC<PaymentProviderDialogProps> = observer(
  ({ id, closeDialog, isCreate }) => {
    const branchId = store.branch.branchId

    const { data: myPermissions, isLoading: myPermissionsIsLoading } = useFetchMyPermissions()
    const { data: paymentProvider, isLoading: locationByIdIsLoading } =
      useFetchPaymentProviderById(id)
    const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)

    const isLoading =
      (locationByIdIsLoading && !isCreate) || myPermissionsIsLoading || isLoadingAccounts

    const isNoData = (!isCreate && !paymentProvider) || !myPermissions || !accounts

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

    if (isLoading) return <PaymentProviderDialogPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <PaymentProviderDialogLogic
        id={id}
        closeDialog={closeDialog}
        isCreate={isCreate}
        myPermissions={myPermissions}
        paymentProvider={paymentProvider}
        accounts={accounts}
      />
    )
  },
)

export const useOpenPaymentProviderDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogId = useRef<string | undefined>()
  const dialogIsCreate = useRef<boolean>(false)

  const openEditPaymentProviderDialog = (id: string) => {
    dialogId.current = id
    dialogIsCreate.current = false
    openPopup()
  }

  const openCreatePaymentProviderDialog = () => {
    dialogId.current = undefined
    dialogIsCreate.current = true
    openPopup()
  }

  const paymentProviderDialog = isOpen ? (
    <PaymentProviderDialog
      closeDialog={closePopup}
      id={dialogId.current}
      isCreate={dialogIsCreate.current}
    />
  ) : null

  return { openEditPaymentProviderDialog, openCreatePaymentProviderDialog, paymentProviderDialog }
}
