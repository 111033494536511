import { forwardRef, InputHTMLAttributes, useRef, ChangeEvent } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { getCommonInputStyles } from '../../logic'
import { ErrorMessage, ErrorMessageType } from '../ErrorMessage'
import { InputLabel } from '../InputLabel'
import { IconType } from 'react-icons'

export type InputHeight = 'small' | 'medium' | 'normal'

interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  hint?: string
  Icon?: IconType
  containerClassName?: string
  errorMessage?: ErrorMessageType
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      label,
      hint,
      Icon,
      containerClassName,
      errorMessage,
      onChangeInput,
      className,
      height = 'normal',
      disabled,
      ...restProps
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null)
    const mergedRef = mergeRefs([ref, innerRef])

    let inputClassName =
      getCommonInputStyles(errorMessage?.isShown, disabled) +
      'block w-full border-2 rounded-lg px-3 text-sm focus:ring-0 transition-all '

    if (height === 'normal') inputClassName += 'h-9.5 '
    if (height === 'medium') inputClassName += 'h-8 '
    if (height === 'small') inputClassName += 'h-7 '

    inputClassName += Icon ? ' pl-7 capitalize' : ''

    // в компонентах где есть sticky мешает position relative. TODO: Нужно найти решение получше
    return (
      <div className={containerClassName}>
        <InputLabel label={label} hint={hint} required={restProps.required} />

        <div className={`${Icon ? 'relative' : ''} w-full`}>
          {Icon && (
            <Icon
              size="1rem"
              className="absolute text-icon-color left-2 top-2/4 -translate-y-2/4"
            />
          )}
          <input
            ref={mergedRef}
            onChange={onChangeInput}
            className={className ? inputClassName + ` ${className}` : inputClassName}
            autoComplete="off"
            disabled={disabled}
            {...restProps}
          />
        </div>
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    )
  },
)
