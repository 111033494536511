import { FC } from 'react'
import {
  CloudFunctionResponseCodes,
  DEFAULT_ALLOWED_REFUND_DAYS,
  DEFAULT_PAYMENT_ITEMS_CONFIG,
  PaymentProviderType,
  ProviderType,
  ProviderTypeList,
  ServerAccountType,
  useCreatePaymentProvider,
  useUpdatePaymentProvider,
} from '@expane/data'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { permissions } from '@expane/logic/permission'
import { CloseButton, Dialog, Input, Modal, NumberInput, SelectDropdown } from '@expane/ui'
import { SaveButton } from 'widgets/Buttons'
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { PaymentProviderKeys } from './PaymentProviderKeys'
import { AccountSelectDropdown } from 'widgets/AccountSelectDropdown'
import { PaymentProviderItemsConfig } from './PaymentProviderItems'
import { formDataForUpdate, PaymentProviderFormValues } from '@expane/logic/payment/providers'

interface PaymentProviderDialogLogicProps {
  isCreate: boolean
  closeDialog: () => void
  id?: string
  paymentProvider: PaymentProviderType | undefined
  myPermissions: string[]
  accounts: ServerAccountType[]
}

export const PaymentProviderDialogLogic: FC<PaymentProviderDialogLogicProps> = observer(
  ({ id: paymentProviderId, paymentProvider, closeDialog, isCreate, myPermissions, accounts }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()

    const { mutateAsync: createMutation } = useCreatePaymentProvider()
    const { mutateAsync: updateMutation } = useUpdatePaymentProvider()

    const [openSnackbar] = useSnackbar()

    const { formState, control, handleSubmit } = useForm<PaymentProviderFormValues>({
      defaultValues: {
        type: paymentProvider?.type || ProviderType.liqPay,
        name: paymentProvider?.name || '',
        publicKey: paymentProvider?.publicKey || '',
        privateKey: paymentProvider?.privateKey || '',
        isChangingKeys: !Boolean(paymentProvider),
        accountId: paymentProvider?.account?.id || undefined,
        allowedRefundDays:
          (paymentProvider?.paymentProviderSettings?.allowedRefundDays &&
            paymentProvider.paymentProviderSettings.allowedRefundDays.toString()) ||
          DEFAULT_ALLOWED_REFUND_DAYS.toString(),
        paymentItemsConfig:
          paymentProvider?.paymentProviderSettings?.paymentItemsConfig ||
          DEFAULT_PAYMENT_ITEMS_CONFIG,
      },
    })
    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

    const mutatePaymentProvider: SubmitHandler<PaymentProviderFormValues> = async data => {
      if (!branchId) {
        openSnackbar(t('submitError'), 'error')
        return
      }

      const {
        name,
        type,
        privateKey,
        publicKey,
        allowedRefundDays,
        accountId,
        paymentItemsConfig,
      } = data

      if (isCreate) {
        const result = await createMutation({
          name,
          type,
          privateKey,
          publicKey,
          accountId,
          branchId,
          allowedRefundDays: Number(allowedRefundDays),
          paymentItemsConfig,
        })

        if (result.updatePaymentProvider?.code === CloudFunctionResponseCodes.successful) {
          openSnackbar(t('paymentProvider.createSuccess'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      } else if (paymentProviderId) {
        const result = await updateMutation(formDataForUpdate(paymentProviderId, data, formState))

        if (result.updatePaymentProvider?.code === CloudFunctionResponseCodes.successful) {
          openSnackbar(t('paymentProvider.updateSuccess'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      }

      closeDialog()
    }

    const isEditingAllowed = myPermissions.includes(permissions.business.set)
    const isSelectTypeDisabled = !isEditingAllowed || !isCreate

    return (
      <>
        <Modal
          close={closePopups}
          confirm={() => {
            if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
              handleSubmit(mutatePaymentProvider)()
          }}
          animation="onlyFadeOut"
        >
          <Dialog>
            <Dialog.Title>{t('paymentProvider.name')}</Dialog.Title>

            <Dialog.Body className="w-152">
              <div className="flex justify-between gap-2">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label={t('title')}
                      required
                      errorMessage={{
                        isShown: Boolean(error),
                        text: t('formError.required'),
                      }}
                      value={value}
                      containerClassName="w-1/2"
                      onChange={onChange}
                      disabled={!isEditingAllowed}
                      autoFocus
                      hint={t('paymentProvider.nameHint')}
                    />
                  )}
                />

                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SelectDropdown
                      required
                      errorMessage={{
                        isShown: Boolean(error),
                        text: t('formError.required'),
                      }}
                      className="w-1/2"
                      label={t('type')}
                      onSelectChange={onChange}
                      value={value}
                      items={ProviderTypeList}
                      hint={t('paymentProvider.typeHint')}
                      disabled={isSelectTypeDisabled}
                    />
                  )}
                />
              </div>

              <PaymentProviderKeys
                isCreate={isCreate}
                isEditingAllowed={isEditingAllowed}
                control={control}
              />

              <div className="flex justify-between gap-2">
                <Controller
                  name="allowedRefundDays"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <NumberInput
                      label={t('paymentProvider.allowedRefundDays')}
                      required
                      errorMessage={{
                        isShown: Boolean(error),
                        text: t('formError.required'),
                      }}
                      value={value}
                      containerClassName="w-1/2"
                      onChange={onChange}
                      disabled={!isEditingAllowed}
                      allowDecimals={false}
                    />
                  )}
                />

                <Controller
                  name="accountId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <AccountSelectDropdown
                      accounts={accounts}
                      label={t('account.name')}
                      required
                      errorMessage={{
                        isShown: Boolean(formState.errors.name),
                        text: t('formError.required'),
                      }}
                      value={value}
                      className="w-1/2"
                      onSelectChange={onChange}
                      disabled={!isEditingAllowed}
                      hint={t('paymentProvider.accountHint')}
                    />
                  )}
                />
              </div>

              <PaymentProviderItemsConfig control={control} isEditingAllowed={isEditingAllowed} />
            </Dialog.Body>

            <Dialog.Footer>
              {isEditingAllowed && (
                <SaveButton
                  onClick={handleSubmit(mutatePaymentProvider)}
                  disabled={formState.isSubmitting || !formState.isDirty}
                  spinner={formState.isSubmitting}
                  isCreate={!Boolean(paymentProviderId && paymentProvider)}
                />
              )}

              <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
            </Dialog.Footer>
          </Dialog>
        </Modal>

        {confirmPopup}
      </>
    )
  },
)
