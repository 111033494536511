import { ServerCardTemplateType, ServerSubscriptionType } from '../../generated/graphql-types'
import { SubscriptionInfo } from '../card'

export const CARD_TYPES = {
  subscription: 1,
  giftCard: 2,
}

export type CardTemplateWithSubscriptionInfoType = Omit<ServerCardTemplateType, 'cards'> & {
  cards: Array<{
    id: number
    activatedAt?: Date | undefined
    canceledDate?: Date | undefined
    createdAt: Date
    subscriptionInfo: SubscriptionInfo
    cardPeriod: number
  }>
}

export interface SubscriptionType extends ServerSubscriptionType {
  subscriptionInfo: SubscriptionInfo
  type: 1 // CARD_TYPES.subscription
}
