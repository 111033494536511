import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { PAYMENT_PROVIDERS_QUERY_KEY } from './queryKeys'
import { CreatePaymentProviderInsertInput, UpdatePaymentProviderSetInput } from './logic'
import { CloudFunctionResult } from '../responses'

export const useUpdatePaymentProvider = () => {
  return useMutation(
    async (
      dto: UpdatePaymentProviderSetInput,
    ): Promise<{ updatePaymentProvider?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation (
            $id: Int
            $name: String
            $publicKey: String
            $privateKey: String
            $accountId: Int
            $allowedRefundDays: Int
            $paymentItemsConfig: jsonb
          ) {
            updatePaymentProvider(
              actionType: update
              id: $id
              name: $name
              publicKey: $publicKey
              privateKey: $privateKey
              accountId: $accountId
              allowedRefundDays: $allowedRefundDays
              paymentItemsConfig: $paymentItemsConfig
            ) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PAYMENT_PROVIDERS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useCreatePaymentProvider = () => {
  return useMutation(
    async (
      dto: CreatePaymentProviderInsertInput,
    ): Promise<{ updatePaymentProvider?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation (
            $name: String
            $type: smallint!
            $publicKey: String
            $privateKey: String!
            $branchId: Int!
            $accountId: Int!
            $allowedRefundDays: Int!
            $paymentItemsConfig: jsonb
          ) {
            updatePaymentProvider(
              actionType: create
              name: $name
              type: $type
              publicKey: $publicKey
              privateKey: $privateKey
              branchId: $branchId
              accountId: $accountId
              allowedRefundDays: $allowedRefundDays
              paymentItemsConfig: $paymentItemsConfig
            ) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PAYMENT_PROVIDERS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
