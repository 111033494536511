import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, useQuery } from '../../api'
import { PAYMENT_PROVIDERS_QUERY_KEY } from './queryKeys'
import {
  paymentProviderAsClientFragment,
  paymentProviderFragment,
} from './paymentProvider.fragments'
import { PaymentProviderAsClientType, PaymentProviderType } from './logic'
import { AS_CLIENT_QUERY_KEY } from '../client'

export const useFetchPaymentProviders = (branchId: number | undefined) => {
  return useQuery<PaymentProviderType[]>(
    [PAYMENT_PROVIDERS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${paymentProviderFragment}
          query ($branchId: Int!) {
            paymentProviders(where: { branchId: { _eq: $branchId } }, order_by: { id: asc }) {
              ...paymentProviderType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.paymentProviders)) {
        return result.paymentProviders
      }

      reportError(new Error('paymentProviders is not an array'), 'error', { result })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchPaymentProviders',
      onError: reportGqlError,
    },
  )
}

export const useFetchPaymentProviderById = (id: string | undefined) => {
  return useQuery<PaymentProviderType>(
    [PAYMENT_PROVIDERS_QUERY_KEY, { id }],
    async () => {
      const result = await request(
        gql`
          ${paymentProviderFragment}
          query ($id: uuid!) {
            paymentProviderById(id: $id) {
              ...paymentProviderType
            }
          }
        `,
        { id },
      )

      if (result.paymentProviderById) return result.paymentProviderById

      reportError(new Error('paymentProviderById does not exist'), 'error', { result })
    },
    {
      queryName: 'useFetchPaymentProviderById',
      onError: reportGqlError,
      enabled: Boolean(id),
    },
  )
}

export const useFetchPaymentProvidersAsClient = (branchId: number | undefined) => {
  return useQuery<PaymentProviderAsClientType[]>(
    [PAYMENT_PROVIDERS_QUERY_KEY, AS_CLIENT_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${paymentProviderAsClientFragment}
          query ($branchId: Int!) {
            paymentProviders(where: { branchId: { _eq: $branchId } }) {
              ...paymentProviderAsClientType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.paymentProviders)) {
        return result.paymentProviders
      }

      reportError(new Error('paymentProviders is not an array'), 'error', { result })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchPaymentProvidersAsClient',
      onError: reportGqlError,
    },
  )
}
