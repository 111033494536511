import {
  mapProviderTypeName,
  PaymentItemsConfig,
  PaymentProviderType,
  useFetchPaymentProviders,
} from '@expane/data'
import { Checkbox, EmptyPlaceholder, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { IoBag } from 'react-icons/io5'

export const PaymentProviderList: FC<{ onRowClick: (item: PaymentProviderType) => void }> = memo(
  ({ onRowClick }) => {
    const { t } = useTranslation()

    const branchId = store.branch.branchId
    const { data, isLoading } = useFetchPaymentProviders(branchId)

    const columns = useMemo<ColumnDef<PaymentProviderType>[]>(
      () => [
        {
          accessorKey: 'name',
          header: t('name'),
          cell: data => data.getValue(),
          size: 200,
        },
        {
          accessorKey: 'type',
          header: t('type'),
          cell: data => <div>{mapProviderTypeName[data.row.original.type] || '-'}</div>,
          size: 100,
        },
        {
          accessorKey: 'account',
          header: t('account.name'),
          cell: data => data.row.original.account.name,
          size: 190,
        },
        {
          accessorKey: 'allowedRefundDays',
          header: t('paymentProvider.allowedRefundDays'),
          cell: data => data.row.original.paymentProviderSettings.allowedRefundDays,
          size: 190,
        },
        {
          accessorKey: 'paymentItemsConfig',
          header: t('paymentProvider.paymentItemsConfig'),
          cell: data => (
            <PaymentItemsConfigCell
              paymentItemsConfig={data.row.original.paymentProviderSettings.paymentItemsConfig}
            />
          ),
          size: 520,
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [t, data],
    )

    return isLoading || (data && data.length > 0) ? (
      <Table columns={columns} data={data} isLoading={isLoading} onRowClick={onRowClick} />
    ) : (
      <EmptyPlaceholder Icon={IoBag} text={t('emptyPlaceholder.archive')} />
    )
  },
)

const PaymentItemsConfigCell: FC<{ paymentItemsConfig: PaymentItemsConfig }> = ({
  paymentItemsConfig,
}) => {
  const { t } = useTranslation()

  const { getModuleSetting } = useBusinessModulesSettings()

  const areGiftCardsEnabled = getModuleSetting('giftCards')
  const areSubscriptionsEnabled = getModuleSetting('subscriptions')
  const areProductsForSaleEnabled = getModuleSetting('productsForSale')
  const areServicesForSaleEnabled = getModuleSetting('servicesForSale')

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        label={t('booking.name')}
        defaultChecked={paymentItemsConfig.booking}
        containerClassName="pointer-events-none"
      />
      {areSubscriptionsEnabled && (
        <Checkbox
          label={t('subscription.name')}
          defaultChecked={paymentItemsConfig.subscription}
          containerClassName="pointer-events-none"
        />
      )}

      {areGiftCardsEnabled && (
        <Checkbox
          label={t('giftCard.name')}
          defaultChecked={paymentItemsConfig.giftCard}
          containerClassName="pointer-events-none"
        />
      )}

      {areServicesForSaleEnabled && (
        <Checkbox
          label={t('serviceType.forSale')}
          defaultChecked={paymentItemsConfig.additionalService}
          containerClassName="pointer-events-none"
        />
      )}

      {areProductsForSaleEnabled && (
        <Checkbox
          label={t('product.name')}
          defaultChecked={paymentItemsConfig.product}
          containerClassName="pointer-events-none"
        />
      )}
    </div>
  )
}
