import {
  ServerPaymentProviderAsClientType,
  ServerPaymentProviderType,
} from '../../generated/graphql-types'

export const DEFAULT_ALLOWED_REFUND_DAYS = 14

export enum ProviderType {
  liqPay = 1,
  monobank,
  // wayForPay,
}

export const mapProviderTypeName: Record<ProviderType, string> = {
  [ProviderType.liqPay]: 'LiqPay',
  [ProviderType.monobank]: 'Monobank',
}

export const ProviderTypeList = [
  { id: ProviderType.liqPay, name: mapProviderTypeName[ProviderType.liqPay] },
  { id: ProviderType.monobank, name: mapProviderTypeName[ProviderType.monobank] },
]

export interface PaymentItemsConfig {
  booking: boolean
  subscription: boolean
  giftCard: boolean
  additionalService: boolean
  product: boolean
}

export const DEFAULT_PAYMENT_ITEMS_CONFIG = {
  booking: true,
  subscription: false,
  giftCard: false,
  additionalService: false,
  product: false,
}

export interface UpdatePaymentProviderSetInput {
  id: string
  name?: string
  publicKey?: string
  privateKey?: string
  accountId?: number
  allowedRefundDays?: number
  paymentItemsConfig?: PaymentItemsConfig
}

interface CreateMonobankInsertInput {
  type: ProviderType.monobank
  name: string
  publicKey?: string
  privateKey: string
  branchId: number
  accountId: number
  allowedRefundDays: number
  paymentItemsConfig?: PaymentItemsConfig
}

export interface CreateOtherInsertInput {
  type: ProviderType.liqPay
  name: string
  publicKey: string
  privateKey: string
  branchId: number
  accountId: number
  allowedRefundDays: number
  paymentItemsConfig?: PaymentItemsConfig
}

export type CreatePaymentProviderInsertInput = CreateMonobankInsertInput | CreateOtherInsertInput

export interface PaymentProviderSettings {
  allowedRefundDays: number
  paymentItemsConfig: PaymentItemsConfig
}

export interface PaymentProviderType extends ServerPaymentProviderType {
  type: ProviderType
  paymentProviderSettings: PaymentProviderSettings
}

export interface PaymentProviderAsClientType extends ServerPaymentProviderAsClientType {
  type: ProviderType
  paymentProviderSettings: {
    paymentItemsConfig: PaymentItemsConfig
  }
}
