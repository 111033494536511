import { FC } from 'react'
import {
  Checkbox,
  CommonPlaceholderDialogProps,
  InputLabel,
  Paper,
  PlaceholderDialog,
  PlaceholderInput,
} from '@expane/ui'
import { useTranslation } from 'react-i18next'

export const PaymentProviderDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog
      closeDialog={closeDialog}
      title={t('paymentProvider.name')}
      className="w-152"
    >
      <div className="flex justify-between gap-2 mb-4">
        <PlaceholderInput label={t('title')} className="w-1/2" />

        <PlaceholderInput className="w-1/2" label={t('type')} />
      </div>

      <Paper className="px-2 pt-2 pb-4">
        <div className="flex gap-2 mb-2">
          <InputLabel required label={t('paymentProvider.keys')} />
        </div>

        <div className="flex gap-2">
          <PlaceholderInput label={t('paymentProvider.publicKey')} className="grow" />
          <PlaceholderInput label={t('paymentProvider.privateKey')} className="grow" />
        </div>
      </Paper>

      <div className="flex justify-between gap-2 mb-4">
        <PlaceholderInput label={t('paymentProvider.allowedRefundDays')} className="w-1/2" />

        <PlaceholderInput label={t('account.name')} className="w-1/2" />
      </div>

      <Paper className="px-2 pt-2 pb-4">
        <InputLabel
          className="mb-2"
          label={t('paymentProvider.paymentItemsConfig')}
          hint={t('paymentProvider.paymentItemsConfigHint')}
        />

        <div className="flex gap-2">
          <Checkbox label={t('booking.name')} defaultChecked={true} disabled />
        </div>
      </Paper>
    </PlaceholderDialog>
  )
}
