import { gql } from 'graphql-request'

export const paymentProviderFragment = gql`
  fragment paymentProviderType on paymentProvider {
    id
    name
    type
    publicKey
    privateKey
    branchId
    createdAt
    account {
      id
      name
    }
    paymentProviderSettings {
      allowedRefundDays
      paymentItemsConfig
    }
  }
`

export const paymentProviderAsClientFragment = gql`
  fragment paymentProviderAsClientType on paymentProvider {
    id
    name
    type
    branchId
    paymentProviderSettings {
      paymentItemsConfig
    }
  }
`
