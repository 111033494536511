import { Hint, Input, InputLabel, Paper, Switch } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProviderType } from '@expane/data'
import { PaymentProviderFormValues } from '@expane/logic/payment/providers'

interface PaymentProviderKeysProps {
  control: Control<PaymentProviderFormValues>
  isEditingAllowed: boolean
  isCreate: boolean
}

export const PaymentProviderKeys: FC<PaymentProviderKeysProps> = ({
  control,
  isEditingAllowed,
  isCreate,
}) => {
  const { t } = useTranslation()

  const watchedIsChangingKeys = useWatch({ control, name: 'isChangingKeys' })
  const watchedType = useWatch({ control, name: 'type' })

  // Only token is used for Monobank
  const isItMono = watchedType === ProviderType.monobank

  const disabled = !isEditingAllowed || !watchedIsChangingKeys

  return (
    <Paper className="px-2 pt-2">
      <div className="flex gap-2 mb-2">
        <InputLabel
          required
          label={isItMono ? t('paymentProvider.key') : t('paymentProvider.keys')}
        />

        {!isCreate ? (
          <>
            <Controller
              name="isChangingKeys"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switch
                  containerClassName="ml-auto"
                  label={t('change')}
                  onChange={onChange}
                  checked={value}
                />
              )}
            />

            <Hint type="warning">
              {isItMono ? t('paymentProvider.tokenHint') : t('paymentProvider.keysHint')}
            </Hint>
          </>
        ) : null}
      </div>

      <div className="flex gap-2">
        {!isItMono && (
          <Controller
            name="publicKey"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('paymentProvider.publicKey')}
                containerClassName="grow"
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.required'),
                }}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        )}

        <Controller
          name="privateKey"
          control={control}
          rules={{ required: watchedIsChangingKeys }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              label={isItMono ? t('paymentProvider.token') : t('paymentProvider.privateKey')}
              containerClassName="grow"
              errorMessage={{
                isShown: Boolean(error),
                text: t('formError.required'),
              }}
              value={value}
              onChange={onChange}
              disabled={disabled}
              type="password"
            />
          )}
        />
      </div>
    </Paper>
  )
}
